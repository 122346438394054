body {
    margin: 0;
    font-family: 'Montserrat', 'Avenir Next Cyr', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #031022 url("https://cdn.streamheroes.gg/assets/template/blurred_bg_sh.png") no-repeat fixed;
}

html {
    scrollbar-width: thin;
    scrollbar-color: #cc1e31 #3f1c20; /* thumb and track color */
}

a {
    text-decoration: none;
}

img {
    max-width: 100%;
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #3f1c20;
}

*::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb {
    background-color: #cc1e31;
}

:root {
    --toastify-toast-width: 578px !important;
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: url(#gradient-svg-progress) !important;
}

.calendly-inline-widget {
    height: 840px !important;
}

.fa-sixteen {
    font-size: 16px;
}

.flying-object {
    z-index: 1;
    transform-origin: center bottom;
    transform: scale(1);
}

.Toastify {
    z-index: 100000;
    position: relative;
}

.Toastify__toast {
    border-radius: 6px !important;
    padding: 8px 8px 12px 8px !important;
}

.Toastify__toast-body {
    padding: 0 !important;
}

.Toastify__toast-icon {
    width: 50px !important;
    height: 50px !important;
}

.Toastify__toast-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.Toastify__close-button {
    align-self: center !important;
}

.Toastify__toast-theme--dark {
    background: rgba(255, 255, 255, 0.2) !important;
    backdrop-filter: blur(50px);
    color: white;
}

.Toastify__toast-theme--rank-up {
    background: rgba(0, 255, 128, 0.2);
    backdrop-filter: blur(50px);
    color: white;
}

.Toastify__toast-theme--xp-collected {
    background: rgba(128, 252, 30, 0.2);
    backdrop-filter: blur(50px);
    color: white;
}

.Toastify__progress-bar {
    background: white;
}

.Toastify__toast-container {
    max-width: 90%;
}

.Toastify__progress-bar {
    height: 3px !important;
}

.RSPBprogression {
    height: inherit;
    border-radius: 50px;
}

.inner-wrapper {
    max-width: 1440px;
    margin: 0 auto;
}

/* Hide Scrollbars But Keep Functionality*/
.hide-scrollbar {
   scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}