@font-face {
    font-family: 'Avenir Next Cyr';
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-ThinItalic.eot');
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-ThinItalic.eot?#iefix') format('embedded-opentype'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-ThinItalic.woff2') format('woff2'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-ThinItalic.woff') format('woff'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-DemiItalic.eot');
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-DemiItalic.eot?#iefix') format('embedded-opentype'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-DemiItalic.woff2') format('woff2'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-DemiItalic.woff') format('woff'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-DemiItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Heavy.eot');
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Heavy.eot?#iefix') format('embedded-opentype'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Heavy.woff2') format('woff2'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Heavy.woff') format('woff'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Bold.eot');
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Bold.eot?#iefix') format('embedded-opentype'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Bold.woff2') format('woff2'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Bold.woff') format('woff'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr Ultra';
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-UltraLightIt.eot');
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-UltraLightIt.eot?#iefix') format('embedded-opentype'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-UltraLightIt.woff2') format('woff2'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-UltraLightIt.woff') format('woff'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-UltraLightIt.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-LightItalic.eot');
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-LightItalic.eot?#iefix') format('embedded-opentype'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-LightItalic.woff2') format('woff2'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-LightItalic.woff') format('woff'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-BoldItalic.eot');
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-BoldItalic.eot?#iefix') format('embedded-opentype'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-BoldItalic.woff2') format('woff2'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-BoldItalic.woff') format('woff'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Regular.eot');
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Regular.eot?#iefix') format('embedded-opentype'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff2') format('woff2'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff') format('woff'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Light.eot');
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Light.eot?#iefix') format('embedded-opentype'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Light.woff2') format('woff2'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Light.woff') format('woff'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Thin.eot');
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Thin.eot?#iefix') format('embedded-opentype'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Thin.woff2') format('woff2'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Thin.woff') format('woff'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-HeavyItalic.eot');
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-HeavyItalic.eot?#iefix') format('embedded-opentype'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-HeavyItalic.woff2') format('woff2'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-HeavyItalic.woff') format('woff'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-HeavyItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-MediumItalic.eot');
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-MediumItalic.eot?#iefix') format('embedded-opentype'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-MediumItalic.woff2') format('woff2'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-MediumItalic.woff') format('woff'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Avenir Next Cyr Ultra';
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.eot');
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.eot?#iefix') format('embedded-opentype'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.woff2') format('woff2'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.woff') format('woff'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Medium.eot');
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Medium.eot?#iefix') format('embedded-opentype'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Medium.woff2') format('woff2'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Medium.woff') format('woff'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Demi.eot');
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Demi.eot?#iefix') format('embedded-opentype'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Demi.woff2') format('woff2'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Demi.woff') format('woff'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Next Cyr';
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Italic.eot');
    src: local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Italic.eot?#iefix') format('embedded-opentype'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Italic.woff2') format('woff2'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Italic.woff') format('woff'),
    local('AvenirNextCyr'), url('./fonts/AvenirNextCyr/AvenirNextCyr-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Pro';
    src: local('GothamPro'), url('./fonts/GothamPro/GothamPro-MediumItalic.eot');
    src: local('GothamPro'), url('./fonts/GothamPro/GothamPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-MediumItalic.woff2') format('woff2'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-MediumItalic.woff') format('woff'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Pro';
    src: local('GothamPro'), url('./fonts/GothamPro/GothamPro-Light.eot');
    src: local('GothamPro'), url('./fonts/GothamPro/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-Light.woff2') format('woff2'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-Light.woff') format('woff'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: local('GothamPro'), url('./fonts/GothamPro/GothamPro-Medium.eot');
    src: local('GothamPro'), url('./fonts/GothamPro/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-Medium.woff2') format('woff2'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-Medium.woff') format('woff'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: local('GothamPro'), url('./fonts/GothamPro/GothamPro-Black.eot');
    src: local('GothamPro'), url('./fonts/GothamPro/GothamPro-Black.eot?#iefix') format('embedded-opentype'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-Black.woff2') format('woff2'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-Black.woff') format('woff'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: local('GothamPro'), url('./fonts/GothamPro/GothamPro.eot');
    src: local('GothamPro'), url('./fonts/GothamPro/GothamPro.eot?#iefix') format('embedded-opentype'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro.woff2') format('woff2'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro.woff') format('woff'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Pro';
    src: local('GothamPro'), url('./fonts/GothamPro/GothamPro-LightItalic.eot');
    src: local('GothamPro'), url('./fonts/GothamPro/GothamPro-LightItalic.eot?#iefix') format('embedded-opentype'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-LightItalic.woff2') format('woff2'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-LightItalic.woff') format('woff'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Pro';
    src: local('GothamPro'), url('./fonts/GothamPro/GothamPro-Bold.eot');
    src: local('GothamPro'), url('./fonts/GothamPro/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-Bold.woff2') format('woff2'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-Bold.woff') format('woff'),
    local('GothamPro'), url('./fonts/GothamPro/GothamPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: local('Oswald'), url('./fonts/Oswald/Oswald-Regular.eot');
    src: local('Oswald'), url('./fonts/Oswald/Oswald-Regular.eot?#iefix') format('embedded-opentype'),
    local('Oswald'), url('./fonts/Oswald/Oswald-Regular.woff2') format('woff2'),
    local('Oswald'), url('./fonts/Oswald/Oswald-Regular.woff') format('woff'),
    local('Oswald'), url('./fonts/Oswald/Oswald-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: local('Oswald'), url('./fonts/Oswald/Oswald-Bold.eot');
    src: local('Oswald'), url('./fonts/Oswald/Oswald-Bold.eot?#iefix') format('embedded-opentype'),
    local('Oswald'), url('./fonts/Oswald/Oswald-Bold.woff2') format('woff2'),
    local('Oswald'), url('./fonts/Oswald/Oswald-Bold.woff') format('woff'),
    local('Oswald'), url('./fonts/Oswald/Oswald-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Oswald';
    src: local('Oswald'), url('./fonts/Oswald/Oswald-BoldItalic.eot');
    src: local('Oswald'), url('./fonts/Oswald/Oswald-BoldItalic.eot?#iefix') format('embedded-opentype'),
    local('Oswald'), url('./fonts/Oswald/Oswald-BoldItalic.woff2') format('woff2'),
    local('Oswald'), url('./fonts/Oswald/Oswald-BoldItalic.woff') format('woff'),
    local('Oswald'), url('./fonts/Oswald/Oswald-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Oswald';
    src: local('Oswald'), url('./fonts/Oswald/Oswald-RegularItalic.eot');
    src: local('Oswald'), url('./fonts/Oswald/Oswald-RegularItalic.eot?#iefix') format('embedded-opentype'),
    local('Oswald'), url('./fonts/Oswald/Oswald-RegularItalic.woff2') format('woff2'),
    local('Oswald'), url('./fonts/Oswald/Oswald-RegularItalic.woff') format('woff'),
    local('Oswald'), url('./fonts/Oswald/Oswald-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('./fonts/BebasNeue/BebasNeue-Regular.eot');
    src: url('./fonts/BebasNeue/BebasNeue-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/BebasNeue/BebasNeue-Regular.woff2') format('woff2'),
    url('./fonts/BebasNeue/BebasNeue-Regular.woff') format('woff'),
    url('./fonts/BebasNeue/BebasNeue-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Book';
    src: url('./fonts/BebasNeue/BebasNeue-Book.eot');
    src: url('./fonts/BebasNeue/BebasNeue-Book.eot?#iefix') format('embedded-opentype'),
    url('./fonts/BebasNeue/BebasNeue-Book.woff2') format('woff2'),
    url('./fonts/BebasNeue/BebasNeue-Book.woff') format('woff'),
    url('./fonts/BebasNeue/BebasNeue-Book.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('./fonts/BebasNeue/BebasNeue-Bold.eot');
    src: url('./fonts/BebasNeue/BebasNeue-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/BebasNeue/BebasNeue-Bold.woff2') format('woff2'),
    url('./fonts/BebasNeue/BebasNeue-Bold.woff') format('woff'),
    url('./fonts/BebasNeue/BebasNeue-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('./fonts/BebasNeue/BebasNeue-Light.eot');
    src: url('./fonts/BebasNeue/BebasNeue-Light.eot?#iefix') format('embedded-opentype'),
    url('./fonts/BebasNeue/BebasNeue-Light.woff2') format('woff2'),
    url('./fonts/BebasNeue/BebasNeue-Light.woff') format('woff'),
    url('./fonts/BebasNeue/BebasNeue-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('./fonts/BebasNeue/BebasNeue-Thin.eot');
    src: url('./fonts/BebasNeue/BebasNeue-Thin.eot?#iefix') format('embedded-opentype'),
    url('./fonts/BebasNeue/BebasNeue-Thin.woff2') format('woff2'),
    url('./fonts/BebasNeue/BebasNeue-Thin.woff') format('woff'),
    url('./fonts/BebasNeue/BebasNeue-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic';
    src: local('CenturyGothic'), url('./fonts/CenturyGothic/CenturyGothic-Bold.eot');
    src: local('CenturyGothic'), url('./fonts/CenturyGothic/CenturyGothic-Bold.eot?#iefix') format('embedded-opentype'),
    local('CenturyGothic'), url('./fonts/CenturyGothic/CenturyGothic-Bold.woff2') format('woff2'),
    local('CenturyGothic'), url('./fonts/CenturyGothic/CenturyGothic-Bold.woff') format('woff'),
    local('CenturyGothic'), url('./fonts/CenturyGothic/CenturyGothic-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic';
    src: local('CenturyGothic'), url('./fonts/CenturyGothic/CenturyGothic.eot');
    src: local('CenturyGothic'), url('./fonts/CenturyGothic/CenturyGothic.eot?#iefix') format('embedded-opentype'),
    local('CenturyGothic'), url('./fonts/CenturyGothic/CenturyGothic.woff2') format('woff2'),
    local('CenturyGothic'), url('./fonts/CenturyGothic/CenturyGothic.woff') format('woff'),
    local('CenturyGothic'), url('./fonts/CenturyGothic/CenturyGothic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}